import validateEmail from 'validator/lib/isEmail';
import validateEmpty from 'validator/lib/isEmpty';
import ip from 'validator/lib/isIP';
import trim from 'validator/lib/trim';

const isEmpty = (value?: string) => !value || validateEmpty(trim(value));

const isUrl = (value?: string) =>
  value && /^(https?:\/\/)?(\*.)?([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}?(\/.*)?$/.test(value);

export const isRequired = (value: string, message: string) =>
  isEmpty(value) ? message : undefined;

export const isMaxLength = (value: string, max: number, message: string) =>
  trim(value).length > max ? message : undefined;

export const isEmail = (value: string, allowEmptyValue = false) => {
  if (allowEmptyValue && isEmpty(value)) {
    return undefined;
  }

  return isEmpty(value) || !validateEmail(trim(value))
    ? 'Please enter a valid email address'
    : undefined;
};

export const isValidStoragePassword = (value: string) =>
  isEmpty(value) || trim(value).length < 8 || trim(value).length > 40
    ? 'Your password must be between 8 and 40 characters'
    : undefined;

export const isPhoneNumber = (value: string) =>
  !/^[\d ()+-]+$/.exec(value) ? 'Please enter a valid phone number' : undefined;

export const isStorageLabelValid = (value: string) =>
  !/^(\w| |-|_)+$/.exec(value)
    ? 'Allowed characters: alphabets, numbers, spaces, hyphen -, underscore _'
    : undefined;

//https://regex101.com/r/xI8cKK/1
export const isObjectStorageNameValid = (value: string) => {
  if (trim(value).length < 3 || trim(value).length > 63) {
    return 'Name must be between 3 and 63 characters long';
  }

  if (!/^[a-z0-9]+([-.][a-z0-9]+)*$/.exec(value)) {
    return 'Uppercase, special characters and underscores cannot be used';
  }

  return undefined;
};

export const isStorageLabelMaxLength = (value: string) =>
  value.length > 30 ? 'Label must have less than 30 characters' : undefined;

export const isPasswordMinLength = (
  value: string,
  message = 'Your password must be at least 8 characters',
) => (!value || value.trim().length < 8 ? message : undefined);

export const isMinAmount = (value: number, min: number) =>
  value < min ? `Minimum amount is $${min}` : undefined;

const TOKEN_MIN_LENGTH = 8;
const TOKEN_MAX_LENGTH = 64;
export const isAccessProtectionTokenValid = (value: string) =>
  isEmpty(value) || trim(value).length < TOKEN_MIN_LENGTH || trim(value).length > TOKEN_MAX_LENGTH
    ? `Token must be between ${TOKEN_MIN_LENGTH} and ${TOKEN_MAX_LENGTH} characters long`
    : undefined;

export const isPasswordMaxLength = (
  value: string,
  message = 'Your password must be less than 40 characters',
) => (value.length > 40 ? message : undefined);

export const isDomainValid = (
  value: string,
  message = 'Origin URL must be valid domain name or IP address',
) => {
  // https://regex101.com/r/1OurRV/1
  const hostPattern =
    /^([a-z0-9_]([a-z0-9-_]{0,61}[a-z0-9_])?\.){0,125}(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)\.(?:[a-z][a-z0-9-]{0,61}[a-z0-9])$/;

  const baseUrl = value.replace(/\/(.+)/, '');

  return ip(baseUrl, 4) || hostPattern.test(baseUrl) ? undefined : message;
};

export const isIp = (value: string) => (ip(value, 4) ? undefined : 'Must be valid IP address');

export const isValidHotlinkDomain = (value: string) =>
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  isUrl(value) || ip(value) || value === 'localhost' ? undefined : 'Must be valid domain';

export const isValueUnique = (value: string, values: string[], valueIndex: number) => {
  const duplicateCount = values.filter(v => v === value).length;
  // If there are 2+ same values, don't show invalid for the first
  const isDuplicate = values.findIndex(v => v === value) !== valueIndex;

  return duplicateCount > 1 && isDuplicate ? 'This item already exists in the list' : undefined;
};

export const isHighwindsParamValid = (value: string, maxLength = 64) => {
  if (value === '') return undefined;

  if (value.length > maxLength) {
    return `Parameter must be less than ${maxLength} characters`;
  }

  return /^[a-zA-Z0-9-._]*$/.exec(value) === null
    ? 'Use only alphanumerics and underscores'
    : undefined;
};

export const isListNonEmpty = (values: string[], valueIndex: number, message: string) => {
  const isListEmpty = values.filter(v => v !== '').length === 0;
  // Show the error in the last empty field
  const isFirstEmptyField = valueIndex === values.length - 1;

  return isListEmpty && isFirstEmptyField ? message : undefined;
};

export const isOriginLabelAlreadyUsed = (value: string, labels: string[]) => {
  const origin = labels.includes(value);

  return origin ? `Label "${value}" is already used` : undefined;
};

export const isAlreadyInList = <T>(value: T, list: T[], message: string) =>
  list.includes(value) ? message : undefined;

export const isTime = (value: string) => {
  const regex = /^([0-2]?[1-9]|[1-2][0-2])(:)([0-5][0-9])(:)([0-5][0-9])$/;

  return regex.exec(value) === null ? 'Enter valid time format' : undefined;
};

export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;
export const WEAK_PASSWORD_TEXT =
  'Please choose a stronger password. Try a mix of lower/upper case letters and numbers.';
export const isValidPassword = (value: string, message?: string) => {
  if (!PASSWORD_REGEX.test(value)) {
    return message ?? WEAK_PASSWORD_TEXT;
  }

  return (
    isPasswordMinLength(value, message ?? 'Use at least 8 characters') ??
    isPasswordMaxLength(value, message) ??
    undefined
  );
};

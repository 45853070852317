import { useField } from 'formik';
import React from 'react';

import { AuthCode } from './AuthCode';

interface IProps {
  name: string;
}

export const FormAuthCode: React.FC<IProps> = ({ name }) => {
  const field = useField({
    name,
    validate: value => (/^\d{6}$/.exec(value) === null ? 'Invalid code' : undefined),
  });

  return (
    <AuthCode
      onChange={value => {
        void field[2].setValue(value);
      }}
    />
  );
};
